import type { NextRequest } from "@/modules/fetch/types"
import type { ObjectToCamel } from "@/modules/format/types"
import type { FooterLink } from "@/modules/headerFooterFO/Footer/types"
import type { I18nLocale } from "@/modules/i18n/types"

import { getResultFromResponse } from "@/modules/apiRequests/handlers"
import { handleFetchError } from "@/modules/fetch/errorHandler"
import { fetcher } from "@/modules/fetch/fetcher"
import { toCamelCaseKeys } from "@/modules/format/object/toCamelCaseKeys"
import { DEFAULT_STORAGE_DURATION } from "@/modules/serverStorage/constants"

import { FOOTER_LINKS_QUERY_KEY } from "./constants"

type GetFooterLinksParams = {
  locale: I18nLocale
  req?: NextRequest
}

type GetFooterLinksResponse = { page_infos: FooterLink[] }

const FOOTER_LINKS_ENDPOINT = "/jobteaser.contentmanagement.v1alpha1/DedicatedHomePageService/ListDedicatedHomePages"

export const getFooterLinks = async (params: GetFooterLinksParams): Promise<FooterLink[]> => {
  const { locale, req } = params

  return fetcher(FOOTER_LINKS_ENDPOINT, {
    nextRequest: req,
    requestInit: {
      body: JSON.stringify({ page: 1, page_size: 5 }),
      headers: {
        "Accept-Language": locale,
      },
      method: "POST",
    },
    serviceDomain: "APIGW_IN_KRAKEND_API",
    storageDuration: DEFAULT_STORAGE_DURATION,
    storageKey: `${FOOTER_LINKS_QUERY_KEY}::${locale}`,
  })
    .then(
      getResultFromResponse<GetFooterLinksResponse, ObjectToCamel<GetFooterLinksResponse>>({
        transformResult: toCamelCaseKeys,
      })
    )
    .then(({ pageInfos }) => pageInfos)
    .catch(handleFetchError(FOOTER_LINKS_ENDPOINT))
}
